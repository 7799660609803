* {
    // font-family: 'roboto-condensed', 'Microsoft Yahei';
    padding: 0;
    margin: 0;
}

/* ul,
div {
    padding: 0;
    margin: 0;
    overflow: -moz-scrollbars-none;// firefox滚动条隐藏，但无法滚动
} */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba($fonts-color, .4);
}

.well {
    background: transparent;
}

// TODO: check where sweetalert2 is buing used. And remove this lib if functionality can be replaced by another one (ng-zorro, for example)
.swal2-popup .swal2-content {
    font-size: 1.7em !important;
}
