@import '../mixin';
$default-text:14px;
$sm-text:12px;
$lg-text:16px;
$body-bg:#fff;
$sidebar-bg:#465294;
$sidebar-fonts-color:#fff;
$card-bg:#fff;
$card-border:#e4e3e3;
$white:#fff;
$black:#000;
$border-color:rgba($black, .1);
$table-border-color:#e6e6e6;
$fonts-color:#26253c;
$primary:#fca400;
$success:#16be9a;
$danger:#fa5167;
$warning:#ff7403;
$info:#2cc3ff;
body {
    background: $body-bg;
}

.bg-default {
    @include linear-gradient(to top right, darken($card-bg, 40%), darken($card-bg, 30%));
}

.bg-primary {
    @include linear-gradient(to top right, $primary, #ffd17c);
}

.bg-success {
    @include linear-gradient(to top right, $success, #42e697);
}

.bg-danger {
    @include linear-gradient(to top right, $danger, #fda09c);
}

.bg-warning {
    @include linear-gradient(to top right, $warning, #fac980);
}

.bg-info {
    @include linear-gradient(to top right, $info, #15f8bf);
}