.btn:focus,
.btn:active:focus,
.btn.active:focus,
{
    outline: none;
}

.btn {
    padding: .3em .8em;
    border: none;
    border-radius: 1.5px;
    @include transition(all 0.2s);
    color: $white;
}

.btn-link {
    color: $fonts-color;
    text-decoration: underline;
}

.btn-default,
.btn-default:disabled:hover,
.btn-default:hover,
.btn-default:active:focus,
.btn-default:focus {
    color: $white;
    @extend .bg-default;
}

.btn-primary {
    @extend .bg-primary;
    @include box-shadow( 0 2px 10px 0 rgba($primary, .5));
    &:active {
        @extend .bg-primary;
    }
}

.btn-success {
    @extend .bg-success;
    @include box-shadow( 0 2px 10px 0 rgba($success, .5));
    &:active {
        @extend .bg-success;
    }
}

.btn-danger {
    @extend .bg-danger;
    @include box-shadow( 0 2px 10px 0 rgba($danger, .5));
    &:active {
        @extend .bg-danger;
    }
}

.btn-warning {
    @extend .bg-warning;
    @include box-shadow( 0 2px 10px 0 rgba($warning, .5));
    &:active {
        @extend .bg-warning;
    }
}

.btn-info {
    @extend .bg-info;
    @include box-shadow( 0 2px 10px 0 rgba($info, .5));
    &:active {
        @extend .bg-info;
    }
}

.btn-sm {
    padding: .35em .8em;
    font-size: $default-text;
}

.btn-xs {
    font-size: $sm-text;
}

.btn-icon {
    padding: .3em .5em;
}

.btn-group-xs>.btn,
.btn-xs {
    padding: 2px 5px;
    font-size: $sm-text;
    line-height: 1.5;
}

.btn-group-sm>.btn,
.btn-sm {
    font-size: $sm-text;
    line-height: 1.5;
}

.btn-group-lg>.btn,
.btn-lg {
    font-size: $lg-text;
}

.btn-group-open {
    .btn-primary {
        @extend .bg-warning;
    }
}

.open>.btn.dropdown-toggle {
    &.btn {
        color: $fonts-color;
    }
    &.btn.btn-default {
        @extend .bg-default;
    }
    &.btn.btn-primary {
        @extend .bg-primary;
    }
    &.btn-success {
        @extend .bg-success;
    }
    &.btn-warning {
        @extend .bg-warning;
    }
    &.btn-danger {
        @extend .bg-danger;
    }
    &.btn-info {
        @extend .bg-info;
    }
}

.btn-group {
    .dropdown-menu {
        padding: 0;
        background: $card-bg;
        overflow: hidden;
        .divider {
            margin: 0;
            background-color: $border-color;
        }
        a {
            color: $fonts-color;
            &:hover {
                background: rgba($black, .1);
                color: $fonts-color;
            }
        }
    }
}

.btn-with-icon {
    span,
    i {
        margin: auto 5px;
    }
}