.modal-content {
    position: relative;
    background: $card-bg;
    border: none;
    border-radius: 3px;
    @include box-shadow(none);
}

.modal-header {
    border-bottom: 1px solid $border-color;
}

.modal-footer {
    border-top: 1px solid $border-color;
}

button.close {
    outline: none;
}

@keyframes modalFadeIn {
    0% {
        opacity: 0;
        top: 0;
        @include transform(scale(.95));
    }
    100% {
        opacity: 1;
        top: 50px;
        @include transform(scale(1));
    }
}

.modal-open {
    .modal-content {
        animation: modalFadeIn .5s both;
    }
}