@import './theme/conf';
@import './theme/base';
@import './theme/fonts';
@import './theme/cover/_card';
@import './theme/cover/_buttons';
@import './theme/cover/_form';
@import './theme/cover/_tables';
@import './theme/cover/_modal';
@import './theme/cover/_pell-editor';
@import './theme/cover/_ng2-select';

html, body {
  min-height: 100%;
  overflow-x: visible;
}

body {
  margin: 0;
  // i think its much nicer with default fonts
  // font-family: 'Montserrat', "Helvetica Neue", sans-serif !important;
}
