$pell-actionbar-color: $card-bg;
$pell-border-color: $border-color;
$pell-border-style: solid;
$pell-border-width: 1px;
$pell-button-height: 30px;
$pell-button-selected-color: darken($card-bg, 10%);
$pell-button-width: 30px;
$pell-content-padding: 10px;
$pell-content-height: 600px;
.pell {
    border: $pell-border-width $pell-border-style $pell-border-color;
    box-sizing: border-box;
}

.pell-content {
    box-sizing: border-box;
    height: $pell-content-height;
    outline: 0;
    overflow-y: auto;
    padding: $pell-content-padding;
    color: $fonts-color;
}

.pell-actionbar {
    background-color: $pell-actionbar-color;
    border-bottom: $pell-border-width $pell-border-style $pell-border-color;
}

.pell-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: $pell-button-height;
    outline: 0;
    width: $pell-button-width;
    vertical-align: bottom;
}

.pell-button-selected {
    background-color: $pell-button-selected-color;
}